.imagePreviewContainer {
  width: auto;
}
.imagePreviewContainer > div {
  display: inline-block;
}

.imagePreviewCol {
  background-image: linear-gradient(
    135deg,
    #f2f2f2 25%,
    #ebebeb 25%,
    #ebebeb 50%,
    #f2f2f2 50%,
    #f2f2f2 75%,
    #ebebeb 75%,
    #ebebeb 100%
  );
  background-size: 28.28px 28.28px;
}

.circle-picker > span > div > span > div {
  border: 1px solid #aaa;
}
