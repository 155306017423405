body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.imageselector input{
  margin:0;padding:0;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
}
.imageselector label {
  border: 1px solid #666;
  cursor:pointer;
  background-size:contain;
  background-repeat:no-repeat;
  background-position: center;
  display:inline-block;
  width:150px;height:60px;
  margin: 5px;
  -webkit-transition: all 100ms ease-in;
     -moz-transition: all 100ms ease-in;
          transition: all 100ms ease-in;
  -webkit-filter: brightness(1.2) grayscale(.3) opacity(.5);
     -moz-filter: brightness(1.2) grayscale(.3) opacity(.5);
          filter: brightness(1.2) grayscale(.3) opacity(.5);
          
}
.imageselector label:hover{
  -webkit-filter: brightness(1.0) grayscale(0) opacity(.9);
     -moz-filter: brightness(1.0) grayscale(0) opacity(.9);
          filter: brightness(1.0) grayscale(0) opacity(.9);
}
.imageselector input:active + label{opacity: .9;}
.imageselector input:checked +label{
    -webkit-filter: none;
       -moz-filter: none;
            filter: none;
}
